<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import router from '@/router'
import axios from 'axios';

export default {
  name: "App",
  methods: {
    mountAxios() {
      window.axios = axios;

    }
  },
  mounted() {
    console.log('App mounted')
    let req_token = localStorage.getItem('request_token');
    let acc_id = localStorage.getItem('account_id');

    if(req_token != null && acc_id == null) {
      console.log('Rerouting to Signin...')
      router.push('signin')
    }
  }
};
</script>