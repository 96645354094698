import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackPrefetch: true */ '../views/Home.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackPrefetch: true */ '../views/Search.vue')
  },
  {
    path: '/discover',
    name: 'discover',
    component: () => import(/* webpackPrefetch: true */ '../views/Discover.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackPrefetch: true */ '../views/About.vue')
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: () => import(/* webpackPrefetch: true */ '../views/Favorite.vue')
  },
  {
    path: '/watchlist',
    name: 'watchlist',
    component: () => import(/* webpackPrefetch: true */ '../views/Watchlist.vue')
  },
  {
    path: '/recommendations',
    name: 'recommendations',
    component: () => import(/* webpackPrefetch: true */ '../views/Recommendations.vue')
  },
  {
    path: '/movies:id',
    name: 'movies',
    component: () => import(/* webpackPrefetch: true */ '../views/Movie.vue'),
    props: true

  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import(/* webpackPrefetch: true */ '../views/Signin.vue'),
  },
  {
    path: '/tv:id',
    name: 'tv',
    component: () => import(/* webpackPrefetch: true */ '../views/Tv.vue'),
    props: true

  },
  {
    path: '/person:id',
    name: 'person',
    component: () => import(/* webpackPrefetch: true */ '../views/Person.vue'),
    props: true

  },
  {
    path: '/licenses',
    name: 'licenses',
    component: () => import('../views/Licenses.vue'),
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
