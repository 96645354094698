import Vue from 'vue'
import App from './App.vue'
import '@/assets/styles/css/styles.css'
import router from './router'

import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);

//init font awesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faFilter, faHome, faBinoculars, faQuestion, faChevronRight, faChevronLeft, faTimes, faPlay, faRedo, faChevronDown, faChevronUp, faStream, faTrash, faStar, faHeart, faPlus, faCheck, faLanguage, faClock, faPersonBooth, faUserNinja, faSortAmountDownAlt, faTv, faFilm, faGlobe, faHandHoldingUsd, faCopyright, faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { faClock as farClock } from "@fortawesome/free-regular-svg-icons";
import { faImdb, faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import smoothscroll from 'smoothscroll-polyfill';

//or you can set componentName default componentName is vue-seamless-scroll
Vue.use(scroll, { componentName: 'vue-scroll' })

import _ from 'lodash';
import './registerServiceWorker'
Object.defineProperty(Vue.prototype, '_', { value: _ });

smoothscroll.polyfill();

library.add([
  faSearch,
  faFilter,
  faHome,
  faBinoculars,
  faQuestion,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faPlay,
  faRedo,
  faChevronDown,
  faChevronUp,
  faStream,
  faTrash,
  faStar,
  faHeart,
  faPlus,
  faCheck,
  faLanguage,
  faClock,
  farClock,
  faUserNinja,
  faSortAmountDownAlt,
  faImdb,
  faFacebook,
  faInstagram,
  faTwitter,
  faTv,
  faFilm,
  faGlobe,
  faHandHoldingUsd,
  faCopyright,
  faLightbulb
])

Vue.component('font-awesome-icon', FontAwesomeIcon)
///

Vue.config.productionTip = true;

new Vue({
  router,
  methods: {
    // Old dark/light theme import
    // setupTheme() {
    //   var elements = document.head.querySelectorAll("style");
    //   var firstrun = true;
    //   elements.forEach(el => {
    //     if (el.innerHTML.startsWith('/*! bulma.io')) {
    //       if (firstrun) {
    //         el.disabled = true;
    //         el.setAttribute('id', 'dark');
    //         firstrun = false;
    //       }
    //       else {
    //         el.setAttribute('id', 'light');
    //       }
    //     }
    //   })
    // },
    // importDarkTheme() {
    //   document.getElementById('light').disabled = true;
    //   document.getElementById('dark').disabled = false;
    // },
    // importLightTheme() {
    //   document.getElementById('dark').disabled = true;
    //   document.getElementById('light').disabled = false;
    // }
  },
  mounted() {
    // Old dark/light theme import
    // import('@/assets/styles/css/stylesdark.css').then(() => {
    //   import('@/assets/styles/css/styles.css').then(() => {
    //     this.setupTheme();
    //   })

    // });
  },
  render: h => h(App)
}).$mount('#app')


//Register service worker if not in production mode
if ('serviceWorker' in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('sw.js');
  });
}
